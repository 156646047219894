import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <h1 className="text-3xl md:text-4xl lg:text-4xl font-black mb-6">
      Hello, I'm{" "}
      <span className="text-blue-600 dark:text-terminal-green tracking-tighter">
        THANT ZIN OO
      </span>
      !
    </h1>
    <p className="mb-6">
      I am a Software Developer from Calgary. Born in Burma, I moved to
      Singapore in 2005 to study and work. I lived in Singapore for more than
      ten years before migrating to Canada.
    </p>
    <p className="mb-6">
      One of my hobbies is keeping a blog and I write mostly in Burmese.
    </p>
    <Link
      to="/blog/"
      className="inline-block bg-blue-600 dark:bg-terminal-green text-white dark:text-black px-4 py-3 rounded"
    >
      Read my blog
    </Link>
  </Layout>
)

export default IndexPage
